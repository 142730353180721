<!--
 * @Description: 添加/编辑可回收物料
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-22 16:31:21
 * @LastEditors: ChenXueLin
-->
<template>
  <div :class="[addVisible ? 'dialogMask' : 'hiddenMask']">
    <el-dialog
      :title="dialogType == 1 ? '新增' : dialogType == 2 ? '编辑' : '详情'"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
      width="500px"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="custom-dialog"
      :before-close="closeDialog"
      :modal-append-to-body="false"
      :modal="false"
    >
      <el-form
        label-width="100px"
        :model="addForm"
        ref="addForm"
        :rules="addFormRules"
      >
        <el-form-item label="物料名称:" prop="materialNo">
          <remote-search
            v-model="addForm.materialNo"
            clearable
            placeholder="物料名称"
            title="物料名称"
            :queryListAPI="queryMaterialList"
            @change="handleSelect"
            :width="350"
            :disabled="dialogType == 2"
            :defaultValue="dialogType == 1 ? {} : addForm"
            :searchData="{
              isEnableEquipNo: false,
              localMaterial: 1,
              pageIndex: 1,
              pageSize: 200
            }"
          ></remote-search>
        </el-form-item>
        <el-form-item label="物料编码:">
          <el-input disabled v-model="materialNo"></el-input>
        </el-form-item>
        <el-form-item label="物料类型:">
          <el-input disabled v-model="addForm.materialTypeName"></el-input>
        </el-form-item>
        <el-form-item label="是否启用批次号:">
          <el-input disabled v-model="addForm.isBatchManageName"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="comments">
          <el-input
            v-model="addForm.comments"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-switch
            v-model="addForm.status"
            :active-value="activeValue"
            active-color="#46BFEA"
            :inactive-value="inactiveValue"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import {
  saveRecyclableMaterial,
  updateRecyclableMaterial,
  queryMaterialList
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "addRecoveredMaterial",
  components: {},
  data() {
    return {
      editDialogLoading: false,
      queryMaterialList,
      materialNo: "",
      addForm: {
        materialNo: "",
        materialName: "",
        materialTypeName: "",
        isBatchManage: "",
        isBatchManageName: "",
        status: 1,
        comments: ""
      },
      addFormRules: {
        materialNo: [
          {
            required: true,
            message: "请选择物料名称",
            trigger: ["blur", "change"]
          }
        ]
      },
      activeValue: 1, //启用id
      inactiveValue: 0 //关闭id
    };
  },
  //dialogType:1新增2编辑3详情
  props: ["addVisible", "dialogType", "detailInfo"],
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.addVisible;
      },
      set(value) {
        this.$emit("update:addVisible", value);
      }
    }
  },
  created() {},
  watch: {
    addVisible(val) {
      if (val && this.dialogType != 1) {
        let {
          materialNo,
          materialName,
          materialTypeName,
          isBatchManage,
          status,
          comments,
          recyclableMaterialId
        } = this.detailInfo;
        this.addForm.materialNo = materialNo;
        this.addForm.materialName = materialName;
        this.addForm.materialTypeName = materialTypeName;
        this.addForm.isBatchManage = isBatchManage;
        this.addForm.isBatchManageName = this.detailInfo.isBatchManage
          ? "是"
          : "否";
        this.addForm.status = status;
        this.addForm.comments = comments;
        this.recyclableMaterialIdList = [recyclableMaterialId];
        this.materialNo = materialNo;
      }
    }
  },
  methods: {
    //选中物料
    handleSelect(val, node) {
      this.addForm.materialTypeName = node.materialTypeName;
      this.addForm.materialName = node.materialName;
      this.addForm.isBatchManage = node.isBatchManage;
      this.addForm.isBatchManageName = node.isBatchManage ? "是" : "否";
      this.materialNo = node.materialNo;
      console.log(node, this.addForm);
    },

    //选择物料名称
    changeRow(value, node, row) {
      row.materialTypeName = node.materialTypeName;
    },
    //点击确定添加
    handleSubmit() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          //新增
          if (this.dialogType == 1) {
            this.createAccessoryReq();
          }
          //编辑
          if (this.dialogType == 2) {
            this.updateAccessoryReq();
          }
        }
      });
    },

    //添加请求
    async createAccessoryReq() {
      try {
        this.editDialogLoading = true;
        let res = await saveRecyclableMaterial(this.addForm);
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //编辑接口
    async updateAccessoryReq() {
      try {
        this.editDialogLoading = true;
        let res = await updateRecyclableMaterial({
          ...this.addForm,
          recyclableMaterialIdList: this.recyclableMaterialIdList
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.visible = false;
      // this.$nextTick(() => {
      //   this.$refs["addForm"].resetFields();
      // });
      this.addForm = {
        materialNo: "",
        materialName: "",
        materialTypeName: "",
        isBatchManage: "",
        isBatchManageName: "",
        status: 1,
        comments: ""
      };
      console.log(2222);

      console.log(this.addForm, "@@@@1111");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/utils.scss";
@import "@/assets/styles/variables.scss";
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;
  }
}
</style>
